import { RumEvent, RumErrorEvent } from "@datadog/browser-rum";

/** This function filters out parts of a path on any domain that may contain PII */
export function sanitizePath(path: string | undefined) {
  if (!path) {
    return undefined;
  }

  let sanitizedPath = path;
  //  query strings can contain a dangerous amount of information, so overriding
  // them is the safest bet
  if (path.includes("?")) {
    const pathBeforeQuery = path.slice(0, path.indexOf("?"));

    sanitizedPath = `${pathBeforeQuery}?*`;
  }

  // portal 2 single manager paths are always followed by manager name, filter it out
  if (path.includes("/i/")) {
    const regex = /\/i\/\w*/g;
    sanitizedPath = sanitizedPath.replace(regex, "/i/*");
  }

  return sanitizedPath;
}

/** Regexes to match against errors. If there is a match, the error will not be sent to Datadog. */
const errorRegexBlockList: RegExp[] = [
  // Browser extensions
  /chrome(-extension)?:\/\//,
  /moz-extension:\/\//,
  /safari(-web)?-extension:\/\//,
  /webkit-masked-url/,
  // Apollo Errors
  /Fetch is aborted/,
  /Failed to fetch/,
  // PDF JS errors
  /Worker was terminated/,
  // Network Blips
  /Loading CSS chunk/,
];

/**
 * There are some errors that we can't do anything about. They add noise to Datadog
 * and make it harder to find and understand real errors.
 */
function filterErrors(event: RumErrorEvent): boolean {
  const shouldFilter = errorRegexBlockList.some((regex) => {
    if (event.error.message.match(regex) || event.error.stack?.match(regex)) return true;
    return false;
  });
  if (shouldFilter) return false;
  return true;
}

export function filterEvents(event: RumEvent): boolean {
  if (event.type === "resource" && event.resource) {
    event.resource.url = sanitizePath(event.resource.url) || "";
  }
  if (event.type === "error" && event.error.resource) {
    if (!filterErrors(event)) return false;
    event.error.resource.url = sanitizePath(event.error.resource?.url) || "";
  }
  event.view.url = sanitizePath(event.view.url) || "";
  event.view.referrer = sanitizePath(event.view.referrer) || "";
  return true;
}
