export const Environments = {
  unknown: "unknown",
  staging: "staging",
  production: "production",
  prodops: "prodops",
  demo: "demo",
  test: "test",
  development: "development",
  cit: "cit",
  beta: "beta",
} as const;
